import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import Header from "./Header";
import Footer from "./Footer";
import reportWebVitals from "./reportWebVitals";
import Country from "./Country";
import Guide from "./Guide.js";
import CheckList from "./CheckList.js";
import Like from "./Like.js";
import Error from "./Error";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Header />
    <div style={{ flex: 1 }}>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/country/:id" element={<Country />} />
        <Route path="/guide/:id" element={<Guide />} />
        <Route path="/checklist/:id" element={<CheckList />} />
        <Route path="/like" element={<Like />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </div>
    <Footer />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
