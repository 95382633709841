import { Box } from "@mui/material";
import axios from "axios";
let SERVER_URL =
  "https://port-0-travel-backend-nx562olf9gh06m.sel3.cloudtype.app";
let CLIENT_URL = "https://travelfun.swygbro.com";
function List2({ title, imgSrc, countryNumber }) {
  const newChecklist = async (e) => {
    e.preventDefault();
    const address = await axios.post(`${SERVER_URL}/country/${countryNumber}`, {
      countryNumber,
    });
    window.location.href = `${CLIENT_URL}/checklist/${address.data}`;
  };
  return (
    <Box
      sx={{
        bgcolor: "#ffffff",
        borderRadius: 2,
        p: 7,
        textAlign: "center",
        boxShadow: "0px 5px 35px rgba(0, 0, 0, 0.15)",
        cursor: "pointer",
      }}
      onClick={newChecklist}
    >
      <img height="200vmin" src={imgSrc} alt="사진" />
      <Box sx={{ color: "black", fontSize: 20, fontWeight: "bold" }}>
        {title}
      </Box>
    </Box>
  );
}

export default List2;
