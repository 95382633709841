import "./App.css";
import { Container } from "@mui/material";
const countryList = ["Singapore", "Tokyo", "Paris", "Sydney", "New York"];
function App() {
  return (
    <div className="App wrapper">
      <div className="subject">여행하는 도시를 선택해주세요</div>
      <Container>
        <div className="map_">
          <img className="mapimg" src="map.png" alt="지도" />
          {countryList.map((name, idx) => {
            return (
              <a href={`/country/${idx + 1}`} key={idx}>
                <div className="marker_" id={`marker${idx + 1}`}>
                  <img
                    className="flightImg"
                    src="/img/FLIGHT.svg"
                    alt="flight SVG"
                  />
                  <span>{name}</span>
                </div>
              </a>
            );
          })}
        </div>
      </Container>
    </div>
  );
}

export default App;
