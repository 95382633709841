import { Box } from "@mui/material";
import "./css/Country.css";

function List1({ title, logo, subtitle, content, id }) {
  return (
    <Box
      sx={{
        bgcolor: "#f9f9f9",
        borderRadius: 2,
        p: 2,
        textAlign: "left",
        border: 1,
        borderColor: "lightgray",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "black",
          fontSize: 20,
          fontWeight: "bold",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <img
          src={logo}
          alt="logo"
          height="20px"
          style={{ display: "inline-block", verticalAlign: "middle" }}
        />
        &ensp;{title}
      </Box>
      <Box sx={{ color: "gray", marginBottom: "10px", marginTop: "15px" }}>
        {subtitle}
      </Box>
      <Box
        sx={{
          color: "black",
          fontSize: 17,
          fontWeight: "medium",
          marginBottom: "10px",
          marginTop: "15px",
        }}
      >
        {content}
      </Box>
    </Box>
  );
}

export default List1;
