import "./css/CheckList.css";
import "./App.css";
import { Grid, Box } from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime"; //시계모양

const gridStyle = {
  textAlign: "left",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  paddingLeft: "5%",
};

const gridStyle2 = {
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  fontWeight: 500,
  letterSpacing: "2px",
  fontSize: "25px",
};

function ComputeTime({ h, m }) {
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={6}
      spacing={1}
      direction="column"
      justifyContent="space-between"
    >
      <Grid container style={{ paddingBottom: "5px" }}>
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
            boxShadow: 3,
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={8} style={gridStyle}>
              <Box sx={{ display: "inline-block" }}>
                <AccessTimeIcon
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    opacity: 0.5,
                  }}
                />
                <span
                  style={{
                    verticalAlign: "middle",
                    opacity: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  &ensp;공항 도착시간
                </span>
              </Box>
            </Grid>
            <Grid item xs={4} style={gridStyle2}>
              {h !== null
                ? (h + 22) % 24 < 10
                  ? "0" + ((h + 22) % 24)
                  : (h + 22) % 24
                : "00"}
              :{m ? (m < 10 ? "0" + m : m) : "00"}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid container style={{ paddingBottom: "5px", paddingTop: "5px" }}>
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
            boxShadow: 3,
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={8} style={gridStyle}>
              <Box sx={{ display: "inline-block" }}>
                <AccessTimeIcon
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    opacity: 0.5,
                  }}
                />
                <span
                  style={{
                    verticalAlign: "middle",
                    opacity: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  &ensp;체크인 마감시간
                </span>
              </Box>
            </Grid>
            <Grid item xs={4} style={gridStyle2}>
              {h !== null
                ? (h + 23) % 24 < 10
                  ? "0" + ((h + 23) % 24)
                  : (h + 23) % 24
                : "00"}
              :{m ? (m < 10 ? "0" + m : m) : "00"}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid container style={{ paddingTop: "5px" }}>
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
            boxShadow: 3,
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={8} style={gridStyle}>
              <Box sx={{ display: "inline-block" }}>
                <AccessTimeIcon
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    opacity: 0.5,
                  }}
                />
                <span
                  style={{
                    verticalAlign: "middle",
                    opacity: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  &ensp;탑승시간
                </span>
              </Box>
            </Grid>
            <Grid item xs={4} style={gridStyle2}>
              {h !== null && m !== null
                ? (m - 30 >= 0 ? h : (h + 23) % 24) < 10
                  ? "0" + (m - 30 >= 0 ? h : (h + 23) % 24)
                  : m - 30 >= 0
                  ? h
                  : (h + 23) % 24
                : "00"}
              :
              {m !== null
                ? (m + 30) % 60 < 10
                  ? "0" + ((m + 30) % 60)
                  : (m + 30) % 60
                : "00"}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ComputeTime;
