import "./App.css";
import { Box, Container, Grid, Typography, Paper } from "@mui/material";
//import GoogleTranslate from "react-google-translate";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
function Footer() {
  const isPC = useMediaQuery({ query: "(min-width : 1064px)" });
  const isTab = useMediaQuery({ query: "(min-width : 600px)" });
  function googleTranslateElementInit() {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "ko",
        includedLanguages: "ko,en",

        layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL,
        autoDisplay: false,
      },
      "google_translate_element"
    );
  }
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: "#F5F5F7",
        boxShadow: "none",
        marginTop: "100px",
      }}
      component="footer"
      square
    >
      <Container
        contain
        maxWidth="lg"
        style={{
          paddingLeft: isPC ? "10vmin" : "5vmin",
          paddingRight: isPC ? "10vmin" : "5vmin",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <Box sx={{ margin: isTab ? "24px" : "16px" }}>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "left",
              display: "flex",
              my: 1,
            }}
          >
            <img src="/img/logoPC.png" alt="logo" style={{ height: "25px" }} />
          </Box>
          <Grid
            container
            spacing={1}
            style={{ marginTop: "10px", marginBottom: "50px" }}
          >
            <Grid item xs={12} sm={6} md={6}>
              ✍️🎨 UI/UX Designer, jeeyoon9758@gmail.com&emsp;
              <a href="https://linkedin.com/in/jeeyoon-kim">
                <img
                  src="/img/linkedin.png"
                  alt="jeeyoon SVG"
                  width="22px"
                  height="22px"
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              ‍💻 SW Developer, seoin712@gmail.com&emsp;
              <a href="https://github.com/seoin0110">
                <img
                  src="/img/github.svg"
                  alt="github SVG"
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                />
              </a>
            </Grid>
          </Grid>
          {/* <GoogleTranslate options={options} /> */}
          <div
            id="google_translate_element"
            style={{ marginBottom: "5px" }}
          ></div>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "left",
              display: "flex",
              mb: 2,
            }}
            style={{ boxShadow: "none" }}
          >
            <Typography
              variant="caption"
              color="initial"
              style={{ boxShadow: "none" }}
            >
              Copyright © Travel Fun. All Rights Reserved.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
}

export default Footer;
