import React, { useState, useEffect } from "react";
import "./css/Guide.css";
import "./App.css";
import { Box, Container, ImageList } from "@mui/material";
import LikeList from "./LikeList";

function Like() {
  const [likeNum, setLikeNum] = useState(0);
  const [items, setItems] = useState([]);
  useEffect(() => {
    let count = 0;
    const items = Object.keys(localStorage).map((local_key) => {
      if (JSON.parse(localStorage.getItem(local_key)).isFavorited === true) {
        count++;
        return <LikeList local_key={local_key} />;
      }
      return null;
    });
    setItems(items);
    setLikeNum(count);
  }, []);
  return (
    <div>
      <Container className="content">
        <div className="name" style={{ fontSize: "30px" }}>
          {likeNum}개의 좋아요
        </div>
        <Box>
          <ImageList
            className="checklist"
            gap={12}
            sx={{
              mb: 8,
              gridTemplateColumns:
                "repeat(auto-fill,minmax(300px,1fr))!important",
            }}
            variant="woven"
            cols={3}
          >
            {items}
          </ImageList>
        </Box>
      </Container>
    </div>
  );
}

export default Like;
