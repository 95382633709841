import React, { useState } from "react";
import {
  Link,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
const imgStyle = {
  color: "black",
  fontWeight: "bold",
};

function ImgList({ imgSrc, title, source, contentLink, idx, id }) {
  const [isFavorited, setIsFavorited] = useState(
    JSON.parse(localStorage.getItem(`${id}_${idx}`)) &&
      JSON.parse(localStorage.getItem(`${id}_${idx}`)).isFavorited
  );
  const handleFavorite = (event) => {
    event.preventDefault();
    setIsFavorited((prev) => !prev);
    localStorage.setItem(
      `${id}_${idx}`,
      JSON.stringify({
        isFavorited: !isFavorited,
        imgSrc,
        title,
        source,
        contentLink,
      })
    );
  };
  return (
    <Link href={contentLink} underline="none">
      <ImageListItem key={idx} sx={{ height: "75%", lineHeight: "40px" }}>
        <img
          className="borderImg"
          src={imgSrc}
          alt={title}
          loading="lazy"
          style={{ borderRadius: "10px" }}
        />

        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <IconButton onClick={handleFavorite}>
            {isFavorited ? (
              <FavoriteIcon
                sx={{
                  color: "#ff2058",
                  height: "30px",
                  width: "30px",
                  padding: "5px",
                }}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{
                  color: "#ff2058",
                  height: "30px",
                  width: "30px",
                  padding: "5px",
                }}
              />
            )}
          </IconButton>
        </div>
        <ImageListItemBar
          title={title}
          subtitle={
            <span
              style={{
                fontWeight: "normal",
                opacity: 0.6,
                fontSize: "15px",
              }}
            >
              {source}
            </span>
          }
          position="below"
          style={imgStyle}
        />
      </ImageListItem>
    </Link>
  );
}

export default ImgList;
