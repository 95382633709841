import "./css/Guide.css";
import "./App.css";
import { Box, Container, ImageList } from "@mui/material";
import ImgList from "./ImgList";
import { useParams } from "react-router";
import listData from "./country.json";
const countryName = ["", "싱가포르", "도쿄", "파리", "시드니", "뉴욕"];

function Guide() {
  const { id } = useParams();
  if (!["1", "2", "3", "4", "5"].includes(id))
    return <div>웹페이지를 표시할 수 없습니다.</div>;
  else
    return (
      <div>
        <Box
          className="subject"
          sx={{
            maxHeight: "20vmin",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            className="countryImg"
            src={`/country/country${id}.jpg`}
            alt="country img"
            style={{
              verticalAlign: "middle",
              width: "100%",
              maxHeight: "initial",
            }}
          ></img>
          <span className="countryName_" style={{ fontSize: "30px" }}>
            {countryName[id]}
          </span>
        </Box>
        <Container className="content">
          <div className="name" style={{ fontSize: "30px" }}>
            여행 콘텐츠 살피기
          </div>
          <Box>
            <ImageList
              className="checklist"
              gap={12}
              sx={{
                mb: 8,
                gridTemplateColumns:
                  "repeat(auto-fill,minmax(300px,1fr))!important",
              }}
              variant="woven"
              cols={3}
            >
              {listData.country[id - 1].map((list, idx) => {
                return (
                  <ImgList
                    imgSrc={list.imgSrc}
                    title={list.title}
                    source={list.source}
                    contentLink={list.contentLink}
                    idx={idx}
                    key={idx}
                    id={id}
                  />
                );
              })}
            </ImageList>
          </Box>
        </Container>
      </div>
    );
}

export default Guide;
