import "./css/Country.css";
import "./App.css";
import { Grid, Box, Container, Link } from "@mui/material";
import { useState, useEffect } from "react";
import List1 from "./List1";
import List2 from "./List2";
import List3 from "./List2_2";
import React from "react";
import { useParams } from "react-router";
import axios from "axios";
import listData from "./country.json";
const countryName = ["", "싱가포르", "도쿄", "파리", "시드니", "뉴욕"];
let SERVER_URL =
  "https://port-0-travel-backend-nx562olf9gh06m.sel3.cloudtype.app";
function Country() {
  const { id } = useParams();
  const [data, setData] = useState();
  const fetchData = async () => {
    const response = await axios.get(`${SERVER_URL}/country/${id}`);
    setData(response.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (!["1", "2", "3", "4", "5"].includes(id))
    return <div>웹페이지를 표시할 수 없습니다.</div>;
  else
    return (
      <div className="wrapper" styles="display:table;">
        <hr
          style={{
            margin: 0,
            backgroundColor: "#cecece",
            border: "none",
            height: "1px",
          }}
        />
        <Container className="content" style={{ marginTop: "0" }}>
          <Box className="info1">
            <Link href={listData.food[id - 1]} underline="none" color="black">
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: "30px",
                  fontSize: "15px",
                }}
              >
                <img
                  className="foodImg"
                  src="/img/icon_food.svg"
                  alt="food SVG"
                  style={{
                    height: "20px",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                />
                &ensp;맛집
              </span>
            </Link>
            <Link href={listData.ticket[id - 1]} underline="none" color="black">
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                <img
                  className="ticketImg"
                  src="/img/icon_ticket.svg"
                  alt="ticket SVG"
                  style={{
                    height: "20px",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                />
                &ensp;티켓 · 투어
              </span>
            </Link>
          </Box>
        </Container>
        <Box
          className="subject"
          sx={{
            maxHeight: "40vmin",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            className="countryImg"
            src={`/country/country${id}.jpg`}
            alt="country img"
            style={{
              verticalAlign: "middle",
              width: "100%",
              maxHeight: "initial",
            }}
          ></img>
          <span className="countryName">{countryName[id]}</span>
        </Box>
        <Container className="content">
          <Grid className="checklist1" container spacing={2}>
            {data &&
              data.map((item, idx) => {
                return (
                  <Grid item xs={6} sm={6} md={3} key={idx + 1}>
                    <List1
                      title={item.title}
                      logo={`/img/list${idx + 1}.svg`}
                      subtitle={item.subtitle}
                      content={item.content}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <div
            className="name"
            style={{
              fontSize: "30px",
              marginTop: "111px",
              marginBottom: "0px",
            }}
          >
            나만의 여행 준비하기
          </div>
          <Grid className="checklist2" container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <List2
                title="체크리스트 작성하기"
                imgSrc="/img/list1.png"
                countryNumber={id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <List3
                title="여행 콘텐츠 살피기"
                imgSrc="/img/list2.png"
                href={`/guide/${id}`}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
}

export default Country;
