import { Box, Link } from "@mui/material";
function List3({ title, imgSrc, href }) {
  return (
    <Link href={href} underline="none">
      <Box
        sx={{
          bgcolor: "#ffffff",
          borderRadius: 2,
          p:7,
          textAlign: "center",
          boxShadow: "0px 5px 35px rgba(0, 0, 0, 0.15)",
        }}
      >
        <img height="200vmin" src={imgSrc} alt="사진" />
        <Box sx={{ color: "black", fontSize: 20, fontWeight: "bold" }}>
          {title}
        </Box>
      </Box>
    </Link>
  );
}

export default List3;
