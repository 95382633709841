import "./App.css";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Link,
  Container,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
const PClogoStyle = {
  marginLeft: "0",
  marginRight: "0",
  paddingLeft: "10vmin",
};

const logoStyle = {
  marginLeft: "0",
  marginRight: "0",
  paddingLeft: "2vmin",
};

const PCheartStyle = {
  marginRight: "0",
  paddingRight: "10vmin",
};
const heartStyle = {
  marginRight: "0",
  paddingRight: "2vmin",
};
function Header() {
  const isPC = useMediaQuery({ query: "(min-width : 1064px)" });
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{
            backgroundColor: "white",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Link href="/">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                style={isPC ? PClogoStyle : logoStyle}
                disableRipple
              >
                {isPC && (
                  <img
                    src="/img/logoPC.png"
                    alt="logo"
                    style={{ height: "35px" }}
                  />
                )}
                {!isPC && (
                  <img
                    src="/img/logo.png"
                    alt="logo"
                    style={{ height: "35px" }}
                  />
                )}
              </IconButton>
            </Link>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={{ color: "#ff4b1f" }}
            />
            <Link href="/like">
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                style={isPC ? PCheartStyle : heartStyle}
                disableRipple
              >
                <img
                  src="/img/icon_heart.svg"
                  alt="heart"
                  style={{
                    height: "20px",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                />
                {isPC && (
                  <span
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    &ensp;좋아요
                  </span>
                )}
              </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
      </Box>
    </Container>
  );
}

export default Header;
