import React, { useState } from "react";
import {
  Link,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
const imgStyle = {
  color: "black",
  fontWeight: "bold",
};

function LikeList({ local_key }) {
  const info = JSON.parse(localStorage.getItem(local_key));
  const [isFavorited, setIsFavorited] = useState(info && info.isFavorited);
  const handleFavorite = (event) => {
    event.preventDefault();
    setIsFavorited((prev) => !prev);
    localStorage.setItem(
      local_key,
      JSON.stringify({
        isFavorited: !isFavorited,
        imgSrc: info.imgSrc,
        title: info.title,
        source: info.source,
        contentLink: info.contentLink,
      })
    );
    console.log(localStorage.getItem(local_key));
  };
  return (
    <Link href={info.contentLink} underline="none">
      <ImageListItem key={local_key} sx={{ height: "75%", lineHeight: "40px" }}>
        <img
          className="borderImg"
          src={info.imgSrc}
          alt={info.title}
          loading="lazy"
          style={{ borderRadius: "10px" }}
        />

        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <IconButton onClick={handleFavorite}>
            {isFavorited ? (
              <FavoriteIcon
                sx={{
                  color: "#ff2058",
                  height: "30px",
                  width: "30px",
                  padding: "5px",
                }}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{
                  color: "#ff2058",
                  height: "30px",
                  width: "30px",
                  padding: "5px",
                }}
              />
            )}
          </IconButton>
        </div>
        <ImageListItemBar
          title={info.title}
          subtitle={
            <span
              style={{
                fontWeight: "normal",
                opacity: 0.6,
                fontSize: "15px",
              }}
            >
              {info.source}
            </span>
          }
          position="below"
          style={imgStyle}
        />
      </ImageListItem>
    </Link>
  );
}

export default LikeList;
