import "./css/CheckList.css";
import "./App.css";
import {
  Grid,
  Box,
  Container,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  ButtonGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router";
import ComputeTime from "./ComputeTime.js";
import { useMediaQuery } from "react-responsive";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"; //달력모양
import EditIcon from "@mui/icons-material/Edit"; //연필모양
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; //체크모양
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"; //삭제
import { useEffect, useState } from "react";
import axios from "axios";
let SERVER_URL =
  "https://port-0-travel-backend-nx562olf9gh06m.sel3.cloudtype.app";
const countryName = ["싱가포르", "도쿄", "파리", "시드니", "뉴욕"];
const checkListGroup = [
  "기내용 가방",
  "세면도구",
  "화장품",
  "의류&신발",
  "소품",
  "기타",
];

const departureTime = {
  textAlign: "center",
  paddingTop: "10px",
  paddingBottom: "10px",
  borderTop: "1px solid rgb(212, 212, 212)",
  borderBottom: "1px solid rgb(212, 212, 212)",
  verticalAlign: "middle",
};

const shareToKakao = (countryNumber, key) => {
  if (window.Kakao) {
    const kakao = window.Kakao;
    if (!kakao.isInitialized()) {
      kakao.init(key);
    }
    kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: "Travel Fun 체크리스트",
        description: countryName[countryNumber],
        imageUrl: "./img/logo.png",
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
    });
  }
};

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F2F2F2",
    color: "#797979",
    "&:hover": {
      backgroundColor: "#FF4B1F",
      color: "white",
    },
  },
  selected: {
    backgroundColor: "#FF4B1F",
    color: "white",
    "&:hover": {
      backgroundColor: "#FF4B1F",
    },
  },
});

function CustomButton({ text, selected, onClick }) {
  const classes = useStyles();
  return (
    <Button
      className={selected ? classes.selected : classes.root}
      onClick={onClick}
      style={{
        marginRight: "8px",
        fontSize: "20px",
        borderRadius: "25px",
        textAlign: "center",
        paddingLeft: "15px",
        paddingRight: "15px",
      }}
    >
      {text}
    </Button>
  );
}

function CheckList() {
  const isPC = useMediaQuery({ query: "(min-width : 1064px)" });
  const [minimalistSelected, setMinimalistSelected] = useState(false);
  const [maximalistSelected, setMaximalistSelected] = useState(false);
  const [summarSelected, setSummarSelected] = useState(false);
  const [winterSelected, setWinterSelected] = useState(false);
  const { id } = useParams();
  const [countryNumber, setCountryNumber] = useState(0);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [lists, setLists] = useState([]);
  const [state, setState] = useState(true);
  const [kakaoKey, setKakaoKey] = useState(0);
  const deleteList = async (list_id, idx, idx2) => {
    setLists((prevValue) => {
      const newValue = [...prevValue];
      newValue[idx].splice(idx2, 1);
      return newValue;
    });
    await axios.post(`${SERVER_URL}/checklist/${id}`, {
      content: list_id,
      queryNumber: 7,
    });
  };
  const appendList = async (e, idx) => {
    if (e.key === "Enter") {
      const response = await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: e.target.value,
        idx: idx,
        queryNumber: 8,
      });
      setLists((prevValue) => {
        const newValue = [...prevValue];
        newValue[idx].push(response.data);
        return newValue;
      });
      e.target.value = "";
    }
  };
  const fetchList = async () => {
    const response = await axios.get(`${SERVER_URL}/checklist/${id}`, {
      id: id,
    });
    if (!response.countryNumber) {
      setState(false);
    } else {
      setState(true);
    }
    setLists(response.data.list);
    setCountryNumber(response.data.countryNumber);
    setYear(response.data.date[0]);
    setMonth(response.data.date[1]);
    setDay(response.data.date[2]);
    setHour(response.data.hour);
    setMinute(response.data.minute);
    setKakaoKey(response.data.key);
    setMinimalistSelected(response.data.minimal);
    setMaximalistSelected(response.data.maximal);
    setSummarSelected(response.data.summar);
    setWinterSelected(response.data.winter);
  };
  //카카오톡 sdk 추가
  useEffect(() => {
    fetchList();
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);
  const yearChange = async (e) => {
    setYear(e.target.value);
    await axios.post(`${SERVER_URL}/checklist/${id}`, {
      content: e.target.value,
      queryNumber: 1,
    });
  };
  const monthChange = async (e) => {
    setMonth(e.target.value);
    await axios.post(`${SERVER_URL}/checklist/${id}`, {
      content: e.target.value,
      queryNumber: 2,
    });
  };
  const dayChange = async (e) => {
    setDay(e.target.value);
    await axios.post(`${SERVER_URL}/checklist/${id}`, {
      content: e.target.value,
      queryNumber: 3,
    });
  };
  const hourChange = async (e) => {
    await axios.post(`${SERVER_URL}/checklist/${id}`, {
      content: e.target.value,
      queryNumber: 4,
    });
  };
  const minuteChange = async (e) => {
    await axios.post(`${SERVER_URL}/checklist/${id}`, {
      content: e.target.value,
      queryNumber: 5,
    });
  };
  const checkChange = async (e) => {
    await axios.post(`${SERVER_URL}/checklist/${id}`, {
      content: e.target.value, //list_id
      checked: e.target.checked, //true or false
      queryNumber: 6,
    });
  };

  const handleMinimalistClick = async () => {
    if (minimalistSelected === true) {
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < newValue[i].length; j++) {
            if (newValue[i][j].custom_id === 1) {
              newValue[i].splice(j, 1);
              j--;
            }
          }
        }
        return newValue;
      });
      await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 1,
        queryNumber: 10,
      });
    } else {
      const response = await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 1,
        queryNumber: 9,
      });
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < response.data[i].length; j++) {
            newValue[i].push(response.data[i][j]);
          }
        }
        return newValue;
      });
    }
    setMinimalistSelected((prevSelected) => !prevSelected);
    if (maximalistSelected === true) {
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < newValue[i].length; j++) {
            if (newValue[i][j].custom_id === 2) {
              newValue[i].splice(j, 1);
              j--;
            }
          }
        }
        return newValue;
      });
      await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 2,
        queryNumber: 10,
      });
    }
    setMaximalistSelected(false);
  };

  const handleMaximalistClick = async () => {
    if (maximalistSelected === true) {
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < newValue[i].length; j++) {
            if (newValue[i][j].custom_id === 2) {
              newValue[i].splice(j, 1);
              j--;
            }
          }
        }
        return newValue;
      });
      await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 2,
        queryNumber: 10,
      });
    } else {
      const response = await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 2,
        queryNumber: 9,
      });
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < response.data[i].length; j++) {
            newValue[i].push(response.data[i][j]);
          }
        }
        return newValue;
      });
    }
    setMaximalistSelected((prevSelected) => !prevSelected);
    if (minimalistSelected === true) {
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < newValue[i].length; j++) {
            if (newValue[i][j].custom_id === 1) {
              newValue[i].splice(j, 1);
              j--;
            }
          }
        }
        return newValue;
      });
      await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 1,
        queryNumber: 10,
      });
    }
    setMinimalistSelected(false);
  };

  const handleSummarClick = async () => {
    if (summarSelected === true) {
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < newValue[i].length; j++) {
            if (newValue[i][j].custom_id === 3) {
              newValue[i].splice(j, 1);
              j--;
            }
          }
        }
        return newValue;
      });
      await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 3,
        queryNumber: 10,
      });
    } else {
      const response = await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 3,
        queryNumber: 9,
      });
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < response.data[i].length; j++) {
            newValue[i].push(response.data[i][j]);
          }
        }
        return newValue;
      });
    }
    setSummarSelected((prevSelected) => !prevSelected);
    if (winterSelected === true) {
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < newValue[i].length; j++) {
            if (newValue[i][j].custom_id === 4) {
              newValue[i].splice(j, 1);
              j--;
            }
          }
        }
        return newValue;
      });
      await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 4,
        queryNumber: 10,
      });
    }
    setWinterSelected(false);
  };

  const handleWinterClick = async () => {
    if (winterSelected === true) {
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < newValue[i].length; j++) {
            if (newValue[i][j].custom_id === 4) {
              newValue[i].splice(j, 1);
              j--;
            }
          }
        }
        return newValue;
      });
      await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 4,
        queryNumber: 10,
      });
    } else {
      const response = await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 4,
        queryNumber: 9,
      });
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < response.data[i].length; j++) {
            newValue[i].push(response.data[i][j]);
          }
        }
        return newValue;
      });
    }
    setWinterSelected((prevSelected) => !prevSelected);
    if (summarSelected === true) {
      setLists((prevValue) => {
        const newValue = [...prevValue];
        for (let i = 0; i < 6; i++) {
          for (let j = 0; j < newValue[i].length; j++) {
            if (newValue[i][j].custom_id === 3) {
              newValue[i].splice(j, 1);
              j--;
            }
          }
        }
        return newValue;
      });
      await axios.post(`${SERVER_URL}/checklist/${id}`, {
        content: 3,
        queryNumber: 10,
      });
    }
    setSummarSelected(false);
  };
  if (state) return <div>웹페이지를 표시할 수 없습니다</div>;
  else
    return (
      <div>
        <Box
          className="subject"
          sx={{
            maxHeight: "20vmin",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            className="countryImg"
            src={`/country/country${countryNumber}.jpg`}
            alt="country img"
            style={{
              verticalAlign: "middle",
              width: "100%",
              maxHeight: "initial",
            }}
          ></img>
          <span className="countryName_" style={{ fontSize: "30px" }}>
            {countryName[countryNumber - 1]}
          </span>
        </Box>
        <Container className="content">
          <Box className="shareMethod">
            <span
              onClick={(e) => shareToKakao(countryNumber - 1, kakaoKey)}
              style={{ cursor: "pointer" }}
            >
              <img
                src="/img/kakaotalk.svg"
                alt="kakaotalk SVG"
                style={{ display: "inline-block", verticalAlign: "middle" }}
              />
              &nbsp;카카오톡으로 공유
            </span>
            &emsp;
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => alert("주소가 복사되었습니다")}
            >
              <span style={{ cursor: "pointer" }}>
                <img
                  src="/img/link.svg"
                  alt="link SVG"
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                />
                &nbsp;URL 복사
              </span>
            </CopyToClipboard>
          </Box>
          <Grid className="checklist1" container spacing={3}>
            <Grid container item xs={12} sm={12} md={6} spacing={1}>
              <Box
                sx={{
                  borderRadius: 2,
                  p: 3,
                  boxShadow: 3,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    marginBottom: "10px",
                    paddingLeft: "5%",
                  }}
                >
                  <CalendarTodayIcon
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      opacity: 0.5,
                    }}
                  />
                  <span
                    style={{
                      verticalAlign: "middle",
                      opacity: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    &ensp;출발시간
                  </span>
                </Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <Grid item xs={5} sm={3} md={3} style={departureTime}>
                    <input
                      onInput={(e) => {
                        const [fieldName, fieldIndex] =
                          e.target.name.split("-");
                        if (e.target.value.length === e.target.maxLength) {
                          if (parseInt(fieldIndex, 10) < 5) {
                            const nextSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) + 1}]`
                            );
                            if (nextSibling !== null) {
                              nextSibling.focus();
                            }
                          }
                        } else if (e.target.value.length === 0) {
                          if (parseInt(fieldIndex, 10) > 0) {
                            const lastSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) - 1}]`
                            );
                            if (lastSibling !== null) {
                              lastSibling.focus();
                            }
                          }
                        }
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      onChange={yearChange}
                      type="number"
                      name="date-1"
                      value={year !== null ? year : ""}
                      placeholder="YYYY"
                      maxLength={4}
                      style={{
                        width: "80px",
                        textAlign: "center",
                        border: "none",
                        outline: "none",
                        fontWeight: 500,
                        letterSpacing: "2px",
                        fontSize: "25px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} style={departureTime}>
                    <input
                      onInput={(e) => {
                        const [fieldName, fieldIndex] =
                          e.target.name.split("-");
                        if (e.target.value.length === e.target.maxLength) {
                          if (parseInt(fieldIndex, 10) < 5) {
                            const nextSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) + 1}]`
                            );
                            if (nextSibling !== null) {
                              nextSibling.focus();
                            }
                          }
                        } else if (e.target.value.length === 0) {
                          if (parseInt(fieldIndex, 10) > 0) {
                            const lastSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) - 1}]`
                            );
                            if (lastSibling !== null) {
                              lastSibling.focus();
                            }
                          }
                        }
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      onChange={monthChange}
                      type="number"
                      name="date-2"
                      value={month !== null ? month : ""}
                      placeholder="MM"
                      maxLength={2}
                      style={{
                        width: "45px",
                        textAlign: "center",
                        border: "none",
                        outline: "none",
                        fontWeight: 500,
                        letterSpacing: "2px",
                        fontSize: "25px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} style={departureTime}>
                    <input
                      onInput={(e) => {
                        const [fieldName, fieldIndex] =
                          e.target.name.split("-");
                        if (e.target.value.length === e.target.maxLength) {
                          if (parseInt(fieldIndex, 10) < 5) {
                            const nextSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) + 1}]`
                            );
                            if (nextSibling !== null) {
                              nextSibling.focus();
                            }
                          }
                        } else if (e.target.value.length === 0) {
                          if (parseInt(fieldIndex, 10) > 0) {
                            const lastSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) - 1}]`
                            );
                            if (lastSibling !== null) {
                              lastSibling.focus();
                            }
                          }
                        }
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      onChange={dayChange}
                      type="number"
                      name="date-3"
                      value={day !== null ? day : ""}
                      placeholder="DD"
                      maxLength={2}
                      style={{
                        width: "40px",
                        textAlign: "center",
                        border: "none",
                        outline: "none",
                        fontWeight: 500,
                        letterSpacing: "2px",
                        fontSize: "25px",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Grid item xs={7} sm={4} md={4} style={departureTime}>
                    <input
                      onChange={hourChange}
                      onInput={(e) => {
                        const [fieldName, fieldIndex] =
                          e.target.name.split("-");
                        if (e.target.value.length >= 1) {
                          setHour(parseInt(e.target.value));
                        } else {
                          setHour(null);
                        }
                        if (e.target.value.length === e.target.maxLength) {
                          if (parseInt(fieldIndex, 10) < 5) {
                            const nextSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) + 1}]`
                            );
                            if (nextSibling !== null) {
                              nextSibling.focus();
                            }
                          }
                        } else if (e.target.value.length === 0) {
                          if (parseInt(fieldIndex, 10) > 0) {
                            const lastSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) - 1}]`
                            );
                            if (lastSibling !== null) {
                              lastSibling.focus();
                            }
                          }
                        }
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      type="number"
                      name="date-4"
                      value={hour !== null ? hour : ""}
                      placeholder="00"
                      maxLength={2}
                      style={{
                        width: "40px",
                        textAlign: "right",
                        border: "none",
                        outline: "none",
                        fontWeight: 500,
                        letterSpacing: "2px",
                        fontSize: "25px",
                      }}
                    />
                    <span
                      style={{
                        fontWeight: 500,
                        letterSpacing: "2px",
                        fontSize: "25px",
                      }}
                    >
                      :
                    </span>
                    <input
                      onInput={(e) => {
                        const [fieldName, fieldIndex] =
                          e.target.name.split("-");
                        if (e.target.value.length >= 1) {
                          setMinute(parseInt(e.target.value));
                        } else {
                          setMinute(null);
                        }
                        if (e.target.value.length === e.target.maxLength) {
                          if (parseInt(fieldIndex, 10) < 5) {
                            const nextSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) + 1}]`
                            );
                            if (nextSibling !== null) {
                              nextSibling.focus();
                            }
                          }
                        } else if (e.target.value.length === 0) {
                          if (parseInt(fieldIndex, 10) > 0) {
                            const lastSibling = document.querySelector(
                              `input[name=date-${parseInt(fieldIndex, 10) - 1}]`
                            );
                            if (lastSibling !== null) {
                              lastSibling.focus();
                            }
                          }
                        }
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      onChange={minuteChange}
                      type="number"
                      name="date-5"
                      value={minute !== null ? minute : ""}
                      placeholder="00"
                      maxLength={2}
                      style={{
                        width: "40px",
                        textAlign: "left",
                        border: "none",
                        outline: "none",
                        fontWeight: 500,
                        letterSpacing: "2px",
                        fontSize: "25px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <ComputeTime h={hour} m={minute} />
          </Grid>
          <Box
            className="additional"
            style={{ marginTop: "2vmin", marginBottom: "7vmin" }}
          >
            <span style={{ opacity: 0.5, fontSize: "14px" }}>
              · 국제선 이용 시, 여유있는 탑승 수속을 위하여 출발 예정시각 최소
              2시간 전에는 공항에 도착하시길 권장드립니다.
              <br />· 일부 공항은 마감시간이 공항사정에 따라 출발 30분전에서
              60분전까지 상이하므로 홈페이지 내 공항 카운터 체크인 이용 시간을
              확인하여 주시기 바랍니다. (예, 인천공항 50분 전 마감, 미주 공항
              1시간 전 마감 등)
            </span>
          </Box>
          <span className="additional title1" style={{ marginRight: "0px" }}>
            짐 싸기 체크리스트
          </span>
          <span
            className="additional"
            style={{
              display: "block",
              float: "right",
            }}
            onClick={() => {
              setEditMode(!editMode);
            }}
          >
            {editMode ? (
              <CheckCircleOutlineIcon
                style={{
                  padding: "7px",
                  paddingLeft: "0px",
                  color: "#20BF55",
                  cursor: "pointer",
                }}
              />
            ) : isPC ? (
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "5px",
                  color: "#FF4B1F",
                  border: "1px solid #FF4B1F",
                  boxShadow: "0 0 0 0.5px #FF4B1F",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "transparent",
                    border: "1px solid #FF4B1F",
                  },
                  paddingLeft: "13px",
                  paddingRight: "13px",
                  marginRight: "7px",
                }}
              >
                <EditOutlinedIcon
                  sx={{ color: "#FF4B1F", height: "15px", width: "15px" }}
                />
                &nbsp;편집
              </Button>
            ) : (
              <EditIcon
                style={{
                  padding: "7px",
                  paddingLeft: "0px",
                  cursor: "pointer",
                }}
              />
            )}
          </span>
          <Box
            display="flex"
            justifyContent="left"
            sx={{
              paddingLeft: isPC ? "10vmin" : "5vmin",
              paddingRight: isPC ? "10vmin" : "5vmin",
              fontSize: "20px",
            }}
            mt={2}
            flexWrap={isPC ? "nowrap" : "wrap"}
          >
            <Box
              sx={{
                mr: 4,
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box sx={{ mr: 1, color: "#FF4B1F", fontWeight: "bold" }}>
                스타일
              </Box>
              <CustomButton
                text="미니멀"
                selected={minimalistSelected}
                onClick={handleMinimalistClick}
              />
              <CustomButton
                text="맥시멀"
                selected={maximalistSelected}
                onClick={handleMaximalistClick}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Box sx={{ mr: 1, color: "#FF4B1F", fontWeight: "bold" }}>
                계절
              </Box>
              <CustomButton
                text="봄•여름"
                selected={summarSelected}
                onClick={handleSummarClick}
              />
              <CustomButton
                text="가을•겨울"
                selected={winterSelected}
                onClick={handleWinterClick}
              />
            </Box>
          </Box>
          <Grid className="checklist1" container spacing={1}>
            {checkListGroup.map((title, idx) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <Box
                    sx={{
                      borderRadius: 2,
                      p: 2,
                      textAlign: "left",
                      borderColor: "lightgray",
                      padding: 0,
                    }}
                  >
                    <Box
                      sx={{
                        color: "black",
                        fontSize: 20,
                        fontWeight: "bold",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        backgroundColor: "#D0F2FD",
                        textAlign: "center",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                    >
                      {title}
                    </Box>
                    <Box
                      sx={{
                        color: "black",
                        fontSize: 20,
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        padding: "15px",
                      }}
                    >
                      {lists[idx] &&
                        lists[idx].map((list, i) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                color: "black",
                                fontSize: 20,
                                fontWeight: "medium",
                                padding: "3px",
                                verticalAlign: "middle",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              key={list.list_id}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="color"
                                  value={list.list_id}
                                  defaultChecked={list.checked}
                                  onChange={checkChange}
                                  style={{
                                    marginRight: "5px",
                                    zoom: 1.5,
                                    accentColor: "#2962D6",
                                  }}
                                />
                                <label className="strikethrough">
                                  {list.checklist_content}
                                </label>
                              </span>
                              {editMode && (
                                <DeleteOutlineIcon
                                  value={list.list_id}
                                  onClick={(e) =>
                                    deleteList(list.list_id, idx, i)
                                  }
                                  style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    opacity: 0.3,
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </Box>
                          );
                        })}
                      {editMode && (
                        <Box
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <TextField
                            id="standard-search"
                            label="+"
                            type="text"
                            variant="standard"
                            style={{ width: "90%" }}
                            onKeyPress={(e) => appendList(e, idx)}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
}

export default CheckList;
